import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '@app/pages/dashboard';
import { AuthLayout } from '@app/components/layout';
import { Request } from '@app/pages/request';
import { useAuth } from '@app/auth/auth-context';
import { SafeBox } from '@app/pages/safebox';
import { routes } from '@app/router/routes';
import { isSafeBoxesAllowed } from '@app/utils/permissions/permissions';

export function AppRouter() {
  const { authenticated, userRoles } = useAuth();

  const isSafeBoxPageAllowed = isSafeBoxesAllowed(userRoles);

  return (
    <AuthLayout>
      <Routes>
        <Route path={routes.dashboard.path} index element={<Dashboard />} />
        <Route path="*" element={<Navigate to={routes.dashboard.path} />} />

        {authenticated && (
          <>
            <Route path={routes.request.path} element={<Request />} />
            {isSafeBoxPageAllowed && <Route path={routes.safebox.path} element={<SafeBox />} />}
          </>
        )}
      </Routes>
    </AuthLayout>
  );
}
