import React, { FC, useMemo, useState } from 'react';
import { Popconfirm, Space, Spin, Typography, Upload } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { addAttachment, deleteAttachment, getAttachments } from '@app/api';
import { RQContent } from '@app/components/rq_content';
import { bytesToMegabytes } from '@app/utils/calculations/calculations';
import { filesAcceptString, maxFileSizeMb } from '@app/types/attachments';
import { AttachmentPreviewModal } from '@app/components/modals/attachment_preview_modal';
import { Tools } from '@app/utils/tools';

import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '@app/assets/icons/download.svg';

interface AttachmentsProps {
  requestId: string;
  isActionsAllowed?: boolean;
  handleAttachmentsStatus: (v: boolean) => void;
}

export const Attachments: FC<AttachmentsProps> = ({ requestId, isActionsAllowed, handleAttachmentsStatus }) => {
  const [previewFile, setPreviewFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    status: attachmentsStatus,
    data: attachments,
    refetch: refetchAttachments,
  } = useQuery({
    queryKey: ['attachments'],
    queryFn: () => getAttachments(requestId),
    onSuccess: (data) => (Tools.isBlank(data) ? handleAttachmentsStatus(true) : handleAttachmentsStatus(false)),
  });

  const { mutate: addAttachmentMutate, isLoading: isAddAttachmentLoading } = useMutation({
    mutationFn: (data: any) => addAttachment(requestId, data),
    onSuccess: async () => {
      refetchAttachments();
    },
  });

  const { mutate: deleteAttachmentMutate, isLoading: isDeleteAttachmentLoading } = useMutation({
    mutationFn: (fileId: string) => deleteAttachment(requestId, fileId),
    onSuccess: async () => {
      refetchAttachments();
    },
  });

  const fileList = useMemo(() => {
    return attachments?.map((a) => ({
      name: a.blob.filename,
      uid: a.id,
      url: a.url,
      size: a.blob.byteSize,
    }));
  }, [attachments]);

  const isDuplicateFile = (file) =>
    attachments.some((a) => a.blob.filename === file.name && a.blob.byteSize === file.size);

  const uploadHandler = async (options: any) => {
    const { file, onSuccess, onError } = options;

    if (isDuplicateFile(file)) {
      toast.info(i18n.t('attachments.fileIsAlreadyExist'));
      return;
    }

    if (bytesToMegabytes(file.size) > maxFileSizeMb) {
      toast.error(i18n.t('attachments.sizeError'));
      return;
    }

    const formData = new FormData();
    formData.append('attachment', file);

    addAttachmentMutate(formData, {
      onSuccess: () => {
        onSuccess('Ok');
      },
      onError: (err) => {
        onError(err);
      },
    });
  };

  return (
    <RQContent status={attachmentsStatus}>
      <Space className={'w-100 mt-4'} direction={'vertical'}>
        <Typography.Title level={3}>{i18n.t('attachments.title')}</Typography.Title>
        <Dragger
          disabled={!isActionsAllowed || isAddAttachmentLoading || isDeleteAttachmentLoading}
          className={'dragger'}
          accept={filesAcceptString}
          multiple
          fileList={fileList}
          onPreview={(file) => {
            setIsModalOpen(true);
            setPreviewFile(file);
          }}
          iconRender={(file) => <span>{bytesToMegabytes(file.size)}MB</span>}
          customRequest={uploadHandler}
          showUploadList={{
            removeIcon: (file) => (
              <Popconfirm
                title={
                  <>
                    <Typography.Title level={2}>{i18n.t('popconfirm.deleteAttachmentTitle')}</Typography.Title>
                    <p>{i18n.t('popconfirm.deleteAttachmentDescription')}</p>
                  </>
                }
                onConfirm={() => deleteAttachmentMutate(file.uid)}
                icon={null}
                okText={i18n.t('popconfirm.yes')}
                cancelText={i18n.t('popconfirm.no')}
                placement={'top'}
                okButtonProps={{ size: 'large', type: 'ghost' }}
                cancelButtonProps={{ size: 'large', type: 'text' }}
              >
                <DeleteIcon />
              </Popconfirm>
            ),
          }}
        >
          {isAddAttachmentLoading || isDeleteAttachmentLoading ? (
            <Spin size={'large'} />
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <DownloadIcon />
              </p>
              <p className="ant-upload-text">{i18n.t('attachments.description')}</p>
              <p className="ant-upload-hint">{i18n.t('attachments.fileInfo')}</p>
            </>
          )}
        </Dragger>
        {previewFile && (
          <AttachmentPreviewModal
            key={previewFile.uid}
            previewId={previewFile.uid}
            requestId={requestId}
            open={isModalOpen}
            previewName={previewFile.name}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Space>
    </RQContent>
  );
};
