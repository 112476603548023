import { Env } from '@app/config/env';

export class Logger {
  static info(message: any, ...optionalParams: any[]) {
    console.log(message, optionalParams);
  }

  static warn(message: any, ...optionalParams: any[]) {
    console.log(message, optionalParams);
  }

  static error(message: any, ...optionalParams: any[]) {
    console.error(message, optionalParams);
  }

  static debug(message: any, ...optionalParams: any[]) {
    if (!Env.get('isDevMode')) {
      return;
    }

    console.log(message, optionalParams);
  }
}
