export class AuthSessionStorage {
  static storeCreds(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getCreds(key: string) {
    return localStorage.getItem(key);
  }

  static resetCreds(key: string) {
    return localStorage.removeItem(key);
  }
}
