import axiosInstance from '@app/config/api/config';
import { RequestTransitionCommands } from '@app/types';

export async function createRefundRequest(payload: object) {
  const res = await axiosInstance.post('refund_requests', payload);
  return res.data;
}

export async function getRefundRequests() {
  const res = await axiosInstance.get('refund_requests');
  return res.data;
}

export async function getRefundRequest(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}

export async function submitRefundRequest(id: string, eventType: RequestTransitionCommands | string) {
  const res = await axiosInstance.post(`refund_requests/${encodeURIComponent(id)}/transition`, { event: eventType });
  return res.data;
}

export async function updateRefundRequest(id: string, payload: any) {
  const res = await axiosInstance.put(`refund_requests/${encodeURIComponent(id)}`, payload);
  return res.data;
}

export async function deleteRefundRequest(id: string) {
  const res = await axiosInstance.delete(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}

export async function getAllowedEvents(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}/transition/actions`);
  return res.data;
}

export async function getAllReceipts(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}/export/receipts`);
  return res.data;
}
