import React, { FC, useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';

import { RequestStatus } from '@app/components/request_status';
import { RequestStatuses } from '@app/types/statuses';
import { RQContent } from '@app/components/rq_content';
import { deleteRefundRequest, getRefundRequests, submitRefundRequest } from '@app/api';
import { useAuth } from '@app/auth/auth-context';
import { RequestTransitionCommands, UserRolesEnum } from '@app/types';
import { Tools } from '@app/utils/tools';
import { isEditRequestActionsAllowed } from '@app/utils/permissions/permissions';

import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as ViewIcon } from '@app/assets/icons/view_mode_icon.svg';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { LanguageContext } from '@app/global_provider';

interface RequestTableProps {
  isDrafts?: boolean;
  setDrafts?: (v: boolean) => void;
  isActionAllowed?: boolean;
}

export const RequestTable: FC<RequestTableProps> = ({ isDrafts, setDrafts, isActionAllowed }) => {
  const { userRoles, user } = useAuth();
  const { isRtl } = React.useContext<any>(LanguageContext);

  const [draftRequests, setDraftRequests] = useState(null);
  const [requests, setRequests] = useState(null);

  const { status, refetch } = useQuery({
    queryKey: ['refund_requests'],
    queryFn: () => getRefundRequests(),
    onSuccess: (data) => {
      if (isActionAllowed) {
        setDraftRequests(data.filter((request) => request.status.name === RequestStatuses.Draft));
        setRequests(data.filter((request) => request.status.name !== RequestStatuses.Draft));
      } else {
        setRequests(data);
      }
    },
  });

  const { mutate: deleteRequestMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: (data: string) => deleteRefundRequest(data),
    onSuccess: async () => {
      refetch();
      toast.success(i18n.t('messages.success.deleteRequest'));
    },
  });

  const { mutate: startRequestMutate, isLoading: isStartLoading } = useMutation({
    mutationFn: (requestId: string) => submitRefundRequest(requestId, RequestTransitionCommands.Submit),
    onSuccess: async () => {
      refetch();
    },
  });

  useEffect(() => {
    isActionAllowed && Tools.isBlank(requests) ? setDrafts(true) : setDrafts(false);
  }, [requests]);

  const isShowStartButton = (record) =>
    !isDrafts && record?.status?.name === RequestStatuses.NotReceived && userRoles.includes(UserRolesEnum.Admin);

  const columns = [
    {
      title: i18n.t('request.tableTitle.requestId'),
      dataIndex: 'requestId',
      key: 'requestId',
    },
    {
      title: i18n.t('request.tableTitle.status'),
      render: (record) => <RequestStatus status={record?.status?.name} />,
    },
    {
      title: i18n.t('request.tableTitle.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => dayjs(record).format('MM/DD/YYYY'),
    },
    {
      title: i18n.t('request.tableTitle.category'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.administration'),
      dataIndex: '',
      key: '',
      render: () => '---',
    },
    {
      title: i18n.t('request.tableTitle.fullAmount'),
      render: (record) => (record.request.receipts ? record.request.totalAmount.toFixed(2) : '---'),
    },
    {
      title: i18n.t('request.tableTitle.recipient'),
      dataIndex: 'recipientId',
      key: 'recipientId',
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {isShowStartButton(record) && (
            <Popconfirm
              title={
                <>
                  <p>{i18n.t('popconfirm.transitionsActionDescription', { event: i18n.t('popconfirm.start') })}</p>
                </>
              }
              onConfirm={() => startRequestMutate(record.id)}
              icon={null}
              okText={i18n.t('popconfirm.start')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'bottomLeft'}
              okButtonProps={{ size: 'large', type: 'text' }}
              cancelButtonProps={{ size: 'large', type: 'ghost' }}
            >
              <Button type={'primary'} size={'small'}>
                {i18n.t('buttons.startTask')}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {isDrafts && (
            <Popconfirm
              title={
                <>
                  <Typography.Title level={2}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
                </>
              }
              className={'danger'}
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'right'}
              onConfirm={() => deleteRequestMutate(record.id)}
              okButtonProps={{ size: 'large', type: 'ghost' }}
              cancelButtonProps={{ size: 'large', type: 'text' }}
            >
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                icon={<DeleteIcon width={20} height={20} />}
              />
            </Popconfirm>
          )}
          <Link className={'d-flex align-items-center'} to={`/request/${record.id}`}>
            {isEditRequestActionsAllowed(userRoles, record?.status, record?.creatorId === user.sub) ? (
              <Tooltip title={i18n.t('request.edit')}>
                <EditIcon />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t('request.view')}>
                <ViewIcon width={12} height={12} style={{ transform: isRtl ? 'none' : 'rotate(90deg)' }} />
              </Tooltip>
            )}
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <RQContent status={status}>
      <Table
        loading={isDeleteLoading || isStartLoading}
        dataSource={isDrafts ? draftRequests : requests}
        pagination={{ size: 'small', hideOnSinglePage: true }}
        columns={columns}
      />
    </RQContent>
  );
};
