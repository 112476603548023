import React, { FC } from 'react';
import { i18n } from '@app/translations/i18n.config';
import { Button, Space, Table } from 'antd';

import { ReactComponent as SaveIcon } from '@app/assets/icons/save.svg';
import { ReactComponent as UndoIcon } from '@app/assets/icons/undo.svg';

interface SafeBoxTableProps {
  isEditMode: boolean;
  onDiscard: () => void;
  onSave: (data: any) => void;
}

export const SafeBoxTable: FC<SafeBoxTableProps> = ({ isEditMode, onDiscard, onSave }) => {
  const columns = [
    {
      title: i18n.t('safebox.tableTitle.amount'),
      render: (record) => '---',
    },
    {
      title: i18n.t('safebox.tableTitle.number'),
      render: (record) => '---',
    },
  ];

  //TODO create handler
  const saveHandler = () => {
    onSave([]);
  };

  return (
    <>
      <Table dataSource={[{}, {}, {}]} pagination={{ size: 'small', hideOnSinglePage: true }} columns={columns} />
      {isEditMode && (
        <Space className={'w-100 justify-content-between my-4 px-3'}>
          <Button type={'default'} icon={<UndoIcon />} onClick={onDiscard}>
            {i18n.t('safebox.discard')}
          </Button>
          <Button type={'primary'} icon={<SaveIcon />} onClick={saveHandler}>
            {i18n.t('safebox.save')}
          </Button>
        </Space>
      )}
    </>
  );
};
