export const Settings = {
  development: {
    isDevMode: true,
    BACKEND_APP_API_URL: 'http://localhost:3000',
    SSO_AUTH_URL: 'https://auth.refunds-app.proxy.abtollc.com/',
    SSO_AUTH_REALM: 'refunds-app',
    SSO_AUTH_CLIENT_ID: 'refunds-app-auth',
    SSO_REDIRECT_URI: 'http://localhost:3001',
  },

  staging: {
    isDevMode: false,
    BACKEND_APP_API_URL: 'https://refunds-app.proxy.abtollc.com/api/',
    SSO_AUTH_URL: 'https://auth.refunds-app.proxy.abtollc.com/',
    SSO_AUTH_REALM: 'refunds-app',
    SSO_AUTH_CLIENT_ID: 'refunds-app-auth',
    SSO_REDIRECT_URI: 'https://refunds-app.proxy.abtollc.com',
  },

  production: {
    isDevMode: false,
    BACKEND_APP_API_URL: 'https://refunds-app.proxy.abtollc.com/api/',
    SSO_AUTH_URL: 'https://auth.refunds-app.proxy.abtollc.com/',
    SSO_AUTH_REALM: 'refunds-app',
    SSO_AUTH_CLIENT_ID: 'refunds-app-auth',
    SSO_REDIRECT_URI: 'https://refunds-app.proxy.abtollc.com',
  },
};
