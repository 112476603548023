export enum RequestTransitionCommands {
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Cancel = 'CANCEL',
}

export type RequestTransitionAction = {
  title: string;
  command: RequestTransitionCommands | string;
  confirmation: string;
  isCommentMandatory: boolean;
};
