import React, { FC, useEffect } from 'react';
import { Tag } from 'antd';

import { RequestStatuses } from '@app/types/statuses';
import { useAuth } from '@app/auth/auth-context';
import { UserRolesEnum } from '@app/types';
import { Tools } from '@app/utils/tools';

interface RequestStatusProps {
  status: RequestStatuses;
  isToastMode?: boolean;
}

export const RequestStatus: FC<RequestStatusProps> = ({ status, isToastMode }) => {
  const { userRoles } = useAuth();
  const isRoleUser = userRoles?.includes(UserRolesEnum.User);

  const inProcessStatuses = [
    RequestStatuses.ApprovedByAdmin,
    RequestStatuses.ApprovedByAuditor,
    RequestStatuses.ApprovedByPlanningTeam,
    RequestStatuses.ReturnedForVerification,
    RequestStatuses.InProcess,
  ];
  const isInProcessStatusForUserRole = isRoleUser && inProcessStatuses.includes(status);

  const getStatusColor = (s) => {
    if (isInProcessStatusForUserRole) {
      return 'blue';
    }

    switch (s) {
      case RequestStatuses.ApprovedByAdmin:
      case RequestStatuses.ApprovedByAuditor:
      case RequestStatuses.ApprovedByPlanningTeam:
        return 'green';
      case RequestStatuses.NotReceived:
      case RequestStatuses.ReturnedForVerification:
        return 'gold';
      case RequestStatuses.InProcess:
        return 'blue';
      case RequestStatuses.ModifyRequest:
        return 'red';
      case RequestStatuses.RefundPaid:
        return 'geekblue';
      case RequestStatuses.Draft:
        return 'volcano';
      case RequestStatuses.Cancelled:
        return 'default';
      default:
        return null;
    }
  };

  if (Tools.isBlank(userRoles) && !isToastMode) return;

  return <Tag color={getStatusColor(status)}>{isInProcessStatusForUserRole ? RequestStatuses.InProcess : status}</Tag>;
};
