import { FC } from 'react';
import dayjs from 'dayjs';
import { Typography } from 'antd';

import { i18n } from '@app/translations/i18n.config';

import { ReactComponent as ClockIcon } from '@app/assets/icons/clock_icon.svg';

interface UpdatesLabelProps {
  updateOwner?: string;
  updatedAt?: string;
  className?: string;
}

export const UpdatesLabel: FC<UpdatesLabelProps> = ({ updateOwner, updatedAt, className }) => {
  return (
    <Typography.Title level={5} className={className}>
      <ClockIcon width={12} height={12} style={{ verticalAlign: 'sub', margin: '0 3px' }} />
      {i18n.t('request.updateLabel', {
        updateOwner: updateOwner ?? '---',
        date: dayjs(updatedAt).format('HH:mm MM.DD.YYYY') ?? '---',
      })}
    </Typography.Title>
  );
};
