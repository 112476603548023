import Ajv from 'ajv';
import ajvKeywords from '@eggjs/ajv-keywords';

import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

const ajv = new Ajv({
  allErrors: true,
  useDefaults: false,
  removeAdditional: true,
  strictRequired: true,
  strict: true,
  keywords: ['uniforms'],
});

ajvKeywords(ajv as any, ['transform']);

function createValidator(schema: object) {
  const validator = ajv.compile(schema);

  return (model: object) => {
    const isFormValid = validator(model);
    return isFormValid ? null : { details: validator.errors };
  };
}

export function initializeSchema(schema: any) {
  const validator = createValidator(schema);
  return new JSONSchemaBridge({ schema, validator });
}
