import React, {FC, useState} from 'react';
import {Button, Image, Modal, Space, Spin, Tooltip, Typography} from 'antd';
import {ModalProps} from 'antd/es/modal';
import {useQuery} from 'react-query';
import {Document, Page, pdfjs, Thumbnail} from 'react-pdf';

import {RQContent} from '@app/components/rq_content';
import {i18n} from '@app/translations/i18n.config';
import {getAttachmentBlob} from "@app/api";
import {AttachmentTypes, maxPreviewFileSizeMb} from "@app/types/attachments";
import {ReactComponent as DownloadIcon} from "@app/assets/icons/download.svg";
import {bytesToMegabytes} from "@app/utils/calculations/calculations";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface AttachmentPreviewModalProps extends ModalProps {
  previewId: string;
  previewName: string;
  requestId: string;
}

export const AttachmentPreviewModal: FC<AttachmentPreviewModalProps> =
  ({previewId, previewName, requestId, ...otherProps}) => {

    const [objectUrl, setObjectUrl] = useState(null);
    const [numPages, setNumPages] = useState([]);

    const {status, data: attachmentBlob} = useQuery({
      queryKey: ['attachmentBlob', previewId],
      queryFn: () => getAttachmentBlob(requestId, previewId),
      onSuccess: async (data) => {
        const blobObjectUrl = URL.createObjectURL(data);
        setObjectUrl(blobObjectUrl)
      }
    });

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
      setNumPages(Array.from({length: numPages}, (_, i) => i + 1));
    }

    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = previewName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      otherProps.onCancel(null);
    };

    return (
      <Modal
        {...otherProps}
        width={650}
        centered
        footer={false}
        title={
          <>
            <Typography.Title level={3} style={{maxWidth: '80%', margin: 'auto'}}>
              {previewName}
            </Typography.Title>
            {objectUrl && (
              <Tooltip placement={'bottom'} title={i18n.t('buttons.download')}>
                <Button
                  type={'dashed'}
                  size={'small'}
                  className={'collapsed position-absolute top-0 mt-3'}
                  icon={<DownloadIcon width={25} height={25}/>}
                  onClick={() => handleDownload()}
                />
              </Tooltip>
            )}
          </>
        }
      >
        <RQContent status={status}>
          <Space
            direction={'vertical'}
            className={'d-flex justify-content-center'}
            style={{maxHeight: '80vh', minHeight: '150px', overflow: 'auto'}}
          >
            {[AttachmentTypes.JPEG, AttachmentTypes.PNG].includes(attachmentBlob?.type) && (
              <Space direction={'vertical'} size={'large'} className={'d-flex align-items-center w-100'}>
                <Image preview={false} src={objectUrl} alt={'attachment-preview'}/>
              </Space>
            )}
            {attachmentBlob?.type === AttachmentTypes.PDF && (
              <>
                {bytesToMegabytes(attachmentBlob.size) < maxPreviewFileSizeMb ? (
                  <Document
                    className={'react-pdf'}
                    file={objectUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {numPages.map((pageNumber) => (
                      <Thumbnail key={pageNumber} pageNumber={pageNumber}/>
                    ))}
                  </Document>
                ) : (
                  <Space direction={'vertical'} size={'large'} className={'d-flex align-items-center w-50 m-auto'}>
                    <Typography.Title level={4}>
                      {i18n.t('attachments.largeFileError', {value: maxPreviewFileSizeMb})}
                    </Typography.Title>
                  </Space>
                )}
              </>
            )}
            {![AttachmentTypes.JPEG, AttachmentTypes.PNG, AttachmentTypes.PDF].includes(attachmentBlob?.type) && (
              <Space direction={'vertical'} size={'large'} className={'d-flex align-items-center w-50 m-auto'}>
                <Typography.Title level={4}>
                  {i18n.t('attachments.notSupportFormat')}
                </Typography.Title>
              </Space>
            )}
          </Space>
        </RQContent>
      </Modal>
    );
  };
