import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table, Input, Typography, Row, Col, Form, Button, Alert } from 'antd';
import { useQuery } from 'react-query';

import { getPaymentsNew } from '@app/api/payments';
import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { i18n } from '@app/translations/i18n.config';

import { ReactComponent as ErrorIcon } from '@app/assets/icons/error.svg';

//Temporary
const data = [
  { denomination: 1000, quantity: 10, amount: 5000 },
  { denomination: 500, quantity: 10, amount: 500 },
  { denomination: 200, quantity: 20, amount: 1200 },
  { denomination: 100, quantity: 15, amount: 500 },
  { denomination: 50, quantity: 20, amount: 100 },
  { denomination: 20, quantity: 10, amount: 60 },
  { denomination: 10, quantity: 15, amount: 40 },
  { denomination: 5, quantity: 35, amount: 15 },
  { denomination: 1, quantity: 150, amount: 25 },
  { denomination: 0.5, quantity: 100, amount: 5 },
  { denomination: 0.25, quantity: 80, amount: 2 },
];

interface RefundFormProps {
  safeboxId: string;
  requestId: string;
  onPaymentAdd: (data: any) => void;
}

export const RefundForm: FC<RefundFormProps> = ({ safeboxId, requestId, onPaymentAdd }) => {
  const [quantities, setQuantities] = useState(data.map((item) => ({ [String(item.denomination)]: 0 })));
  const [differenceValue, setDifferenceValue] = useState(null);

  const handleQuantityChange = (value, index, key, quantity) => {
    const newQuantities = [...quantities];
    newQuantities[index][key] = parseInt(value) > quantity ? quantity : parseInt(value) || 0;
    setQuantities(newQuantities);
  };

  const { status: paymentStatus, data: payment } = useQuery({
    queryKey: [requestId],
    queryFn: () => getPaymentsNew(requestId, safeboxId),
  });

  const updateQuantities = (combination) => {
    const updatedQuantities = quantities.map((item) => {
      const [denominationStr] = Object.keys(item);
      const denomination = parseFloat(denominationStr);
      const quantity = combination[denomination] || 0;
      return { [denominationStr]: quantity };
    });

    setQuantities(updatedQuantities);
  };

  useEffect(() => {
    if (Tools.isPresent(payment)) {
      updateQuantities(payment.combination);
    }
  }, [payment]);

  const columns = [
    {
      title: 'Denomination',
      dataIndex: 'denomination',
      key: 'denomination',
    },
    {
      title: 'د.إ Amount',
      dataIndex: 'denomination',
      key: 'denominationAmount',
      render: (text, record) => <span>{record.denomination}</span>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => <span>{record.quantity}</span>,
    },
    {
      title: 'Enter Quantity',
      render: (text, record, index) => (
        <Input
          min={0}
          max={record.quantity}
          value={quantities[index][record.denomination]}
          onChange={(e) => handleQuantityChange(e.target.value, index, record.denomination, record.quantity)}
          style={{ width: 88 }}
        />
      ),
    },
    {
      title: 'د.إ Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (text, record, index) => <span>{quantities[index][record.denomination] * record.denomination}</span>,
    },
  ];

  const totalAmount = useMemo(
    () =>
      quantities.reduce((sum, item) => {
        const [denominationStr, quantity] = Object.entries(item)[0];
        return sum + parseFloat(denominationStr) * quantity;
      }, 0),
    [quantities],
  );

  const isTotalDifferAmount = useMemo(() => {
    if (payment?.amount == null) return false;
    const difference = Math.abs(totalAmount - payment.amount);
    setDifferenceValue(difference);
    return difference > 5;
  }, [totalAmount, payment]);

  const createCombination = (quantitiesArr) => {
    return quantitiesArr.reduce((result, item) => {
      const [denomination, quantity] = Object.entries(item)[0];
      if (quantity !== 0) {
        result[denomination] = quantity;
      }
      return result;
    }, {});
  };

  const handleAddPayment = () => {
    const data = {
      safeboxId,
      amount: totalAmount,
      combination: createCombination(quantities),
    };
    onPaymentAdd(data);
  };

  return (
    <RQContent status={paymentStatus}>
      <>
        <Typography className={'text-dark-violet with-bottom-border fs-6 m-0'}>
          {i18n.t('refund.typeQuantityDescription')}
        </Typography>

        <Table className={'min-padding'} dataSource={data} columns={columns} pagination={false} rowKey="denomination" />

        <Row justify="space-between" className={'mt-2'}>
          <Col style={{ width: 'calc(100% - 200px)' }} className={'ps-3 d-flex flex-row-reverse'}>
            {isTotalDifferAmount && (
              <Alert
                description={i18n.t('messages.error.amountDiffer', { value: differenceValue })}
                type="error"
                showIcon
                icon={<ErrorIcon width={20} height={15} />}
              />
            )}
          </Col>
          <Col style={{ maxWidth: 50 }}>
            <Typography className={'fs-6 m-0 text-grey'}>Total:</Typography>
          </Col>
          <Col style={{ width: 150, padding: '0 8px' }}>
            <Typography className={'fs-6 m-0 d-flex'}>
              {totalAmount}
              <Typography className={'fs-6 m-0 text-grey'}>د.إ</Typography>
            </Typography>
          </Col>
        </Row>
        <Row justify="space-between" align={'middle'} className={'mt-4'}>
          <Col>
            <Typography className={'fs-4 m-0 d-flex'}>
              <Typography className={'mb-0 text-grey mx-1'}>Total Refund:</Typography>
              {payment?.amount}
              <Typography className={'fs-6 mb-0 text-grey mt-1 mx-1'}>د.إ</Typography>
            </Typography>
          </Col>
          <Col>
            <Button
              onClick={() => handleAddPayment()}
              disabled={isTotalDifferAmount}
              style={{ minWidth: 148 }}
              type={'primary'}
            >
              Get Paid
            </Button>
          </Col>
        </Row>
      </>
    </RQContent>
  );
};
