import { toast } from 'react-toastify';
import { i18n } from '@app/translations/i18n.config';
import { Logger } from '@app/utils/logger/logger-service';
import { Env } from '@app/config/env';

export const requestErrorHandler = (error: any) => {
  Logger.error(error);

  if (Env.get('isDevMode')) {
    return;
  }

  if (error.response?.data?.message) {
    toast.error(error.response?.data?.message);
  } else {
    toast.error(i18n.t('messages.error.requestError'));
  }
};
