export const MAX_COMMENT_CONTENT_LENGTH = 130;

export interface Comment {
  id: string;
  title: string;
  body: string;
  authorId: string;
  authorName: string;
  authorRole: string;
  showToEmployee: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CommentPayload {
  title: string;
  body: string;
  showToEmployee: boolean;
}
