import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Space, Typography } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { ReactComponent as BlockIcon } from '@app/assets/icons/block.svg';
import { deleteRefundRequest, getAllowedEvents, submitRefundRequest } from '@app/api';
import { RequestTransitionAction, RequestTransitionCommands } from '@app/types';
import { RequestStatuses } from '@app/types/statuses';
import { RQContent } from '@app/components/rq_content';
import { useAuth } from '@app/auth/auth-context';
import { CommentModal } from '@app/components/modals/comment_modal';
import { RequestStatus } from '@app/components/request_status';
import { isRefundAllowed } from '@app/utils/permissions/permissions';
import { RefundModal } from '@app/components/modals/refund_modal';
import { Tools } from '@app/utils/tools';

interface ActionButtonProps {
  request: any;
  disableSubmit?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({ request, disableSubmit }) => {
  const navigate = useNavigate();
  const { user, userRoles } = useAuth();

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [commentMandatoryCommand, setCommentMandatoryCommand] = useState(null);

  const { status, data: allowedActions } = useQuery({
    queryKey: ['allowed_events', request?.id],
    queryFn: () => getAllowedEvents(request?.id),
  });

  const { mutate: submitRequestMutate, isLoading: isSubmitLoading } = useMutation({
    mutationFn: (transitionAction: RequestTransitionCommands | string) => {
      return submitRefundRequest(request?.id, transitionAction);
    },
    onSuccess: async (data) => {
      navigate('/');
      toast.success(
        <>
          {i18n.t('messages.success.submitRequest', { requestId: data.requestId })}
          <RequestStatus status={data?.status?.name} isToastMode />
        </>,
      );
    },
  });

  const submitRequestHandler = (transitionAction: RequestTransitionAction) => {
    if (transitionAction.isCommentMandatory) {
      setIsCommentModalOpen(true);
      setCommentMandatoryCommand(transitionAction.command);
    } else {
      submitRequestMutate(transitionAction.command);
    }
  };

  const { mutate: deleteRequestMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: () => deleteRefundRequest(request?.id),
    onSuccess: async () => {
      navigate('/');
      toast.success(i18n.t('messages.success.deleteRequest'));
    },
  });

  const getButtonType = (transitionAction: RequestTransitionAction) => {
    switch (transitionAction.command) {
      case RequestTransitionCommands.Submit:
        return [RequestStatuses.Draft, RequestStatuses.ModifyRequest].includes(request?.status?.name) &&
          user.sub === request?.creatorId
          ? 'primary'
          : 'text';

      case RequestTransitionCommands.Cancel:
      case RequestTransitionCommands.Reject:
        return 'ghost';

      default:
        return 'default';
    }
  };

  return (
    <RQContent status={status} emptyElement={<></>}>
      <Space size={'middle'}>
        {allowedActions?.map((transitionAction: RequestTransitionAction) => (
          <Popconfirm
            key={transitionAction.command}
            title={
              <>
                <Typography.Title level={2} className={'text-capitalize'}>
                  {transitionAction.title}
                </Typography.Title>
                <p>
                  {transitionAction.confirmation ||
                    i18n.t('popconfirm.transitionsActionDescription', { event: transitionAction.command })}
                </p>
              </>
            }
            disabled={disableSubmit || isSubmitLoading || isDeleteLoading}
            onConfirm={() => submitRequestHandler(transitionAction)}
            icon={null}
            okText={i18n.t('popconfirm.yes')}
            cancelText={i18n.t('popconfirm.no')}
            placement={'bottomLeft'}
            okButtonProps={{ size: 'large', type: 'text' }}
            cancelButtonProps={{ size: 'large', type: 'ghost' }}
          >
            <Button
              disabled={disableSubmit || isSubmitLoading || isDeleteLoading}
              loading={isSubmitLoading}
              className={'text-capitalize'}
              type={getButtonType(transitionAction)}
            >
              {transitionAction.title}
            </Button>
          </Popconfirm>
        ))}
        {[RequestStatuses.Draft].includes(request?.status?.name) && (
          <Popconfirm
            title={
              <>
                <Typography.Title level={2}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
              </>
            }
            onConfirm={() => deleteRequestMutate()}
            icon={null}
            okText={i18n.t('popconfirm.yes')}
            cancelText={i18n.t('popconfirm.no')}
            placement={'bottomLeft'}
            okButtonProps={{ size: 'large', type: 'text' }}
            cancelButtonProps={{ size: 'large', type: 'ghost' }}
          >
            <Button
              disabled={isDeleteLoading || isSubmitLoading}
              loading={isDeleteLoading}
              icon={<BlockIcon />}
              type={'ghost'}
            >
              {i18n.t('buttons.delete')}
            </Button>
          </Popconfirm>
        )}

        {Tools.isPresent(request) && isRefundAllowed(userRoles, request?.status?.key) && (
          <Button type={'primary'} onClick={() => setIsRefundModalOpen(true)}>
            {i18n.t('refund.title')}
          </Button>
        )}

        <CommentModal
          requestId={request?.id}
          open={isCommentModalOpen}
          onCancel={() => {
            setIsCommentModalOpen(false);
            setCommentMandatoryCommand(null);
          }}
          onSuccess={() => submitRequestMutate(commentMandatoryCommand)}
        />

        {Tools.isPresent(request) && isRefundAllowed(userRoles, request?.status?.key) && (
          <RefundModal
            request={request}
            open={isRefundModalOpen}
            onCancel={() => {
              setIsRefundModalOpen(false);
            }}
          />
        )}
      </Space>
    </RQContent>
  );
};
