import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo } from '@app/components/logo';
import { UserContainer } from '@app/components/user/user_container';
import { i18n } from '@app/translations/i18n.config';
import { routes } from '@app/router/routes';

import { ReactComponent as DashboardIcon } from '@app/assets/icons/dashboard.svg';
import { ReactComponent as SafeboxIcon } from '@app/assets/icons/currency_exchange.svg';
import { ReactComponent as Arrow } from '@app/assets/icons/left_arrow.svg';
import { useAuth } from '@app/auth/auth-context';
import { isSafeBoxesAllowed } from '@app/utils/permissions/permissions';

export const AuthLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userRoles } = useAuth();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState(null);

  const isSafeBoxPageAllowed = isSafeBoxesAllowed(userRoles);

  const items = [
    {
      path: routes.dashboard.path,
      label: i18n.t('pageTitles.dashboard'),
      key: routes.dashboard.key,
      icon: <DashboardIcon />,
    },
    ...(isSafeBoxPageAllowed
      ? [
          {
            path: routes.safebox.path,
            label: i18n.t('pageTitles.safeboxManagement'),
            key: routes.safebox.key,
            icon: <SafeboxIcon />,
          },
        ]
      : []),
  ];

  const handleNavigation = (key: string) => {
    const { path } = items.find((item) => item.key === key);
    navigate(path);
  };

  useEffect(() => {
    const currentRoute = Object.values(routes).find(({ path }) => pathname === path);

    currentRoute ? setSelectedKey([currentRoute.key]) : setSelectedKey(null);
  }, [pathname]);

  return (
    <Layout>
      <Sider
        className={'py-4 px-3'}
        width={'270px'}
        collapsedWidth={'72px'}
        collapsed={isCollapsed}
        collapsible
        reverseArrow
        defaultCollapsed
        onCollapse={() => setIsCollapsed((prev) => !prev)}
        trigger={<Arrow />}
      >
        <Logo isCollapsed={isCollapsed} />
        <Menu
          theme="light"
          mode="inline"
          onClick={(item) => handleNavigation(item.key)}
          selectedKeys={selectedKey}
          items={items}
        />
        <UserContainer isCollapsed={isCollapsed} />
      </Sider>
      <Layout>{children}</Layout>
    </Layout>
  );
};
