export const routes = {
  dashboard: {
    path: '/',
    key: '1',
  },
  safebox: {
    path: '/safebox',
    key: '2',
  },
  request: {
    path: '/request/:id',
    key: '3',
  },
};
