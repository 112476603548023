import React, { useMemo, useState } from 'react';
import { Button, Col, Row, Segmented, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { RequestTable } from '@app/components/tables/request_table';
import { RequestModal } from '@app/components/modals/request_modal';
import { i18n } from '@app/translations/i18n.config';
import { AppHeader } from '@app/components/app_header';
import { useAuth } from '@app/auth/auth-context';
import { isAddRequestActionAllowed } from '@app/utils/permissions/permissions';

import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';

export const Dashboard = () => {
  const { userRoles } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrafts, setIsDrafts] = useState(false);

  const isActionAllowed = isAddRequestActionAllowed(userRoles);

  const requestTabOptions = useMemo(() => {
    if (isActionAllowed) {
      return [i18n.t('request.tabs.requests'), i18n.t('request.tabs.drafts')];
    } else {
      return [i18n.t('request.tabs.requests')];
    }
  }, [isActionAllowed]);

  const segmentedChangeHandler = (value: string) => {
    if (value === i18n.t('request.tabs.drafts')) {
      setIsDrafts(true);
    } else {
      setIsDrafts(false);
    }
  };

  return (
    <>
      <AppHeader pageTitle={i18n.t('pageTitles.dashboard')} />

      <Content className={'mx-2 mt-2 p-4'}>
        <Row className={'h-100'}>
          <Col span={24} className={'h-100 mb-3'}>
            <Space className={'w-100 justify-content-between'}>
              <Segmented
                value={isDrafts ? i18n.t('request.tabs.drafts') : i18n.t('request.tabs.requests')}
                onChange={segmentedChangeHandler}
                options={requestTabOptions}
              />
              {isActionAllowed && (
                <Button icon={<PlusIcon />} size={'small'} type={'default'} onClick={() => setIsModalOpen(true)}>
                  {i18n.t('request.new')}
                </Button>
              )}
            </Space>
            <Content className={'sub-content-white p-2 rounded-top-0'} style={{ minHeight: '50%' }}>
              <RequestTable isDrafts={isDrafts} setDrafts={setIsDrafts} isActionAllowed={isActionAllowed} />
            </Content>
          </Col>
          {isModalOpen && <RequestModal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} />}
        </Row>
      </Content>
    </>
  );
};
