import React, {FC, useContext, useState} from 'react';
import {Button, Comment, Empty, Space, Tag, Tooltip, Typography} from "antd";
import {useQuery} from "react-query";
import dayjs from "dayjs";

import {i18n} from "@app/translations/i18n.config";
import {UserAvatar} from "@app/components/user/user_avatar";
import {CommentModal} from "@app/components/modals/comment_modal";
import {getComments} from "@app/api";
import {RQContent} from "@app/components/rq_content";
import {MAX_COMMENT_CONTENT_LENGTH} from "@app/types/comments";
import {Tools} from "@app/utils/tools";
import {useAuth} from "@app/auth/auth-context";
import {isAddCommentActionAllowed} from "@app/utils/permissions/permissions";
import {RequestStatuses} from "@app/types/statuses";

import {ReactComponent as PlusIcon} from "@app/assets/icons/plus.svg";
import {ReactComponent as InfoIcon} from "@app/assets/icons/info.svg";
import {LanguageContext} from "@app/global_provider";

interface CommentsProps {
  requestId: string;
  requestStatus: RequestStatuses;
  requestCreatorId: string;
}

export const Comments: FC<CommentsProps> = ({requestId, requestStatus, requestCreatorId}) => {
  const {user, userRoles} = useAuth();
  const {isRtl} = useContext<any>(LanguageContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});


  const {status, data: comments} = useQuery({
    queryKey: ['comments', requestId],
    queryFn: () => getComments(requestId),
  });

  const toggleExpand = (id: string) => {
    setExpandedComments((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderContent = (content: string, isExpanded: boolean) => {
    if (isExpanded || content.length <= MAX_COMMENT_CONTENT_LENGTH) {
      return content;
    }
    return `${content.slice(0, MAX_COMMENT_CONTENT_LENGTH)}...`;
  };

  const isActionAllowed = isAddCommentActionAllowed(userRoles)

  return (
    <Space.Compact
      block
      direction={'vertical'}
      style={{maxHeight: '87vh', overflowY: 'auto'}}
      className={'w-100 h-100 light-grey-bg border-radius-large p-2'}>
      <Space.Compact
        className={'w-100 light-grey-bg justify-content-between p-2 mb-2 sticky-top'}
        style={{top: '-0.5rem', overflow: 'visible', zIndex: 2}}
      >
        <Typography.Title level={3}>{i18n.t('comments.title')}</Typography.Title>
        {isActionAllowed && (
          <Button
            onClick={() => setIsModalOpen(true)}
            icon={<PlusIcon/>}
            size={'small'}
            type={'default'}>
            {i18n.t('comments.add')}
          </Button>
        )}
      </Space.Compact>
      <RQContent status={status}>
        {Tools.isBlank(comments) ? (
          <Empty
            className={'py-3 px-5'}
            image={false}
            description={
              <>
                <p>{i18n.t('comments.emptyText')} {isActionAllowed && i18n.t('comments.addText')}</p>
                {isActionAllowed && (
                  <Button
                    className={'m-auto'}
                    icon={<PlusIcon/>}
                    size={'small'}
                    type={'default'}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {i18n.t('comments.add')}
                  </Button>
                )}
              </>
            }
          />
        ) : (
          <>
            {comments?.map((c) => {
              const isExpanded = expandedComments[c.id] || false;

              return (
                <Comment
                  key={c.id}
                  author={
                    <Space className={'w-100 justify-content-between'}>
                      {user.sub === c.authorId ? i18n.t('comments.you') : c.authorName}
                      {user.sub !== c.authorId && (
                        <Tag color={'gold'}>
                          {Tools.humanize(c.authorRole)}
                        </Tag>
                      )}
                    </Space>
                  }
                  avatar={<UserAvatar className={user.sub === c.authorId && 'owner-color'} title={c.authorName}/>}
                  datetime={dayjs(c.updatedAt).format('HH:mm MM.DD.YYYY')}
                  content={
                    <>
                      <p>
                        {!c.showToEmployee && (
                          <Tooltip title={i18n.t('comments.hideForEmployeeLabel')}>
                            <InfoIcon
                              className={isRtl ? 'ms-2' : 'me-2'}
                              style={{
                                cursor: 'pointer',
                                verticalAlign: 'sub',
                              }}
                            />
                          </Tooltip>
                        )}
                        {renderContent(c.body, isExpanded)}
                      </p>
                      {c.body.length > MAX_COMMENT_CONTENT_LENGTH && (
                        <Button size={'small'} type="link" onClick={() => toggleExpand(c.id)}>
                          {isExpanded ? i18n.t('comments.hide') : i18n.t('comments.showMore')}
                        </Button>
                      )}
                    </>
                  }
                />
              )
            }).reverse()}
          </>
        )}
      </RQContent>
      <CommentModal
        requestId={requestId}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </Space.Compact>
  );
};
