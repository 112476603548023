import React from 'react';
import { createRoot } from 'react-dom/client';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

import { App } from '@app/App';
import { KeycloakConfig, KeycloakInitOptions } from '@app/config/auth/keycloak-config';

import './styles/styles.less';

const container = document.getElementById('root');
const root = createRoot(container);
const authClient = new Keycloak(KeycloakConfig);

root.render(
  <ReactKeycloakProvider authClient={authClient} initOptions={KeycloakInitOptions}>
    <App />
  </ReactKeycloakProvider>,
);
