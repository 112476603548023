import axiosInstance from '@app/config/api/config';

export async function getPaymentsNew(requestId: string, safeboxId) {
  const res = await axiosInstance.get(`refund_requests/${requestId}/payments/new`, {
    params: { safebox_id: safeboxId },
  });

  return res.data;
}

export async function addPayment(requestId: string, payload) {
  const res = await axiosInstance.post(`refund_requests/${requestId}/payments`, payload);

  return res.data;
}
