import axiosInstance from "@app/config/api/config";
import {CommentPayload} from "@app/types/comments";

export async function getComments(requestId: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(requestId)}/comments`);
  return res.data;
}

export async function addComment(requestId: string, payload: CommentPayload) {
  const res = await axiosInstance.post(`refund_requests/${encodeURIComponent(requestId)}/comments`, payload);
  return res.data;
}

export async function deleteComment(requestId: string, userId: string) {
  const res = await axiosInstance.delete(`refund_requests/${encodeURIComponent(requestId)}/comments/${encodeURIComponent(userId)}`);
  return res.data;
}
