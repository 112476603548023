import { RefundRequestStatusesEnum, StatusCategoryEnum, StatusEntity, UserRolesEnum } from '@app/types';

export const isEditRequestActionsAllowed = (
  userRoles: Array<string>,
  status: StatusEntity,
  isOwner: boolean,
): boolean => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);

  if (status.statusCategory === StatusCategoryEnum.ToDo) {
    return false;
  }

  switch (status.key) {
    case RefundRequestStatusesEnum.Draft:
    case RefundRequestStatusesEnum.ModifyRequestInProgress:
      return isUser || isOwner || isAdmin;
    case RefundRequestStatusesEnum.InProcess:
      return isAdmin && !isOwner;
    case RefundRequestStatusesEnum.ReturnedForVerification:
      return isAdmin && !isOwner;
    default:
      return false;
  }
};

export const isAddCommentActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);

  return isUser || isAdmin || isAuditor || isPlanning;
};

export const isShowReceiptCategory = (userRoles: Array<string>) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);

  return isAdmin || isAuditor || isPlanning;
};

export const isChangeCategoryForAuditorAllowed = (
  userRoles: Array<string>,
  statusKey: RefundRequestStatusesEnum | string,
) => {
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.RefundReadyForPayment;

  return isAuditor && isAllowedStatus;
};

export const isAddRequestActionAllowed = (userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);

  return isUser || isAdmin;
};

export const isSafeBoxesAllowed = (userRoles: Array<string>) => {
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);

  return isPlanning;
};

export const isRefundAllowed = (userRoles: Array<string>, statusKey: RefundRequestStatusesEnum | string) => {
  const isAdmin = userRoles.includes(UserRolesEnum.Admin);
  const isAllowedStatus = statusKey === RefundRequestStatusesEnum.RefundReadyForPayment;

  return isAdmin && isAllowedStatus;
};
