import React, { FC, useEffect, useState } from 'react';
import { Modal, Radio, Typography } from 'antd';
import { ModalProps } from 'antd/es/modal';

import { i18n } from '@app/translations/i18n.config';
import { useMutation, useQuery } from 'react-query';
import { getSafeboxes } from '@app/api/safeboxes';
import { RQContent } from '@app/components/rq_content';
import { RefundForm } from '@app/components/modals/refund_modal/refund-form';
import { addPayment } from '@app/api/payments';
import { useNavigate } from 'react-router-dom';
import { routes } from '@app/router/routes';
import { toast } from 'react-toastify';

interface RefundModalProps extends ModalProps {
  request: any;
}

export const RefundModal: FC<RefundModalProps> = ({ request, ...otherProps }) => {
  const navigate = useNavigate();

  const [currentSafeboxId, setCurrentSafeboxId] = useState(null);

  const { status: safeboxesStatus, data: safeboxes } = useQuery({
    queryKey: ['safeboxes'],
    queryFn: () => getSafeboxes(),
  });

  const { mutate: addPaymentMutate, isLoading: isAddPaymentLoading } = useMutation({
    mutationFn: (data: string) => addPayment(request.id, data),
    onSuccess: async () => {
      navigate(routes.dashboard.path);
      toast.success(i18n.t('messages.success.requestPaid'));
    },
    onError: () => {
      setCurrentSafeboxId(null);
      otherProps.onCancel(null);
    },
  });

  useEffect(() => {
    if (otherProps.open) {
      setCurrentSafeboxId(null);
    }
  }, [otherProps.open]);

  return (
    <Modal
      {...otherProps}
      footer={false}
      centered
      width={680}
      maskClosable={false}
      title={<Typography.Title level={3}>{i18n.t('refund.title')}</Typography.Title>}
    >
      <RQContent status={safeboxesStatus}>
        <>
          <Typography className={'text-dark-violet fs-6 mb-3'} style={{ marginTop: '-25px' }}>
            {i18n.t('refund.description')}
          </Typography>
          <Radio.Group
            className={'w-100 mb-3'}
            value={currentSafeboxId}
            onChange={(e) => setCurrentSafeboxId(e?.target?.value)}
          >
            {safeboxes?.map((s) => (
              <Radio className={'w-100'} key={s.id} value={s.id}>
                <p className={'m-0'}>{s.name}</p>
                <p className={'m-0'}>16,750 د.إ</p>
              </Radio>
            ))}
          </Radio.Group>
          {currentSafeboxId && !isAddPaymentLoading && (
            <RefundForm safeboxId={currentSafeboxId} requestId={request.id} onPaymentAdd={addPaymentMutate} />
          )}
        </>
      </RQContent>
    </Modal>
  );
};
