import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal, Typography, Form } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { createRefundRequest } from '@app/api';
import { useAuth } from '@app/auth/auth-context';

export const RequestModal: FC<ModalProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { user } = useAuth();
  const [recipientId, setRecipientId] = useState(user.ecn);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) => createRefundRequest(data),
    onSuccess: async (data) => {
      navigate(`/request/${data.id}`);
    },
    onError: async (error) => {
      setRecipientId(user.ecn);
    },
  });

  useEffect(() => {
    let timeoutId;
    if (props.open && inputRef.current) {
      setRecipientId(user.ecn);
      timeoutId = setTimeout(() => inputRef.current.focus(), 0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.open, inputRef]);

  return (
    <Modal
      {...props}
      width={450}
      centered
      afterClose={() => setRecipientId(user.ecn)}
      title={<Typography.Title level={3}>{i18n.t('request.new')}</Typography.Title>}
    >
      <Form layout={'vertical'} className={'p-2'}>
        <Form.Item label={i18n.t('request.recipientLabel')}>
          <Input
            ref={inputRef}
            value={recipientId}
            onChange={(e) => setRecipientId(e.target.value)}
            onPressEnter={() => recipientId && mutate({ recipient_id: recipientId })}
          />
        </Form.Item>

        {/*<Form.Item label={i18n.t('request.departmentNumber', { departmentNumber: user.dep })} />*/}

        <Button
          loading={isLoading}
          type={'primary'}
          disabled={!recipientId}
          className={'w-50 mt-5 mx-auto'}
          onClick={() => mutate({ recipient_id: recipientId })}
        >
          {i18n.t('request.add')}
        </Button>
      </Form>
    </Modal>
  );
};
