import React, {useState} from 'react';
import {Content} from 'antd/es/layout/layout';
import {Button, Card, Col, Row, Space, Typography} from 'antd';
import {useQuery} from 'react-query';

import {AppHeader} from '@app/components/app_header';
import {i18n} from '@app/translations/i18n.config';
import {SafeBoxTable} from '@app/components/tables/safebox_table';
import {getSafeboxes} from '@app/api/safeboxes';
import {RQContent} from '@app/components/rq_content';

import {ReactComponent as EditIcon} from '@app/assets/icons/edit.svg';

export const SafeBox = () => {
  const [editBoxId, setEditBoxId] = useState(null);

  const {status: safeboxesStatus, data: safeboxes} = useQuery({
    queryKey: ['safeboxes'],
    queryFn: () => getSafeboxes(),
  });

  return (
    <>
      <AppHeader pageTitle={i18n.t('pageTitles.safeboxManagement')}/>

      <Content className={'mx-2 mt-2 p-4'}>
        <RQContent status={safeboxesStatus}>
          <Row gutter={24}>
            {safeboxes?.map((s) => (
              <Col span={8} className={'mb-3'} key={s.id}>
                <Card
                  bordered={false}
                  title={
                    <Space className={'w-100 justify-content-between'}>
                      <Typography.Title level={3}>{s.name}</Typography.Title>
                      {editBoxId !== s.id && (
                        <Button
                          type={'dashed'}
                          size={'small'}
                          className={'collapsed'}
                          onClick={() => setEditBoxId(s.id)}
                          icon={<EditIcon width={20} height={20}/>}
                        />
                      )}
                    </Space>
                  }
                >
                  <SafeBoxTable
                    isEditMode={editBoxId === s.id}
                    onDiscard={() => setEditBoxId(null)}
                    //TODO add save
                    onSave={() => alert('not working yet')}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </RQContent>
      </Content>
    </>
  );
};
