import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Form, Modal, Space, Typography} from 'antd';
import {ModalProps} from 'antd/es/modal';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from "react-toastify";

import {i18n} from '@app/translations/i18n.config';
import TextArea from "antd/es/input/TextArea";
import {addComment} from "@app/api";
import {CommentPayload} from "@app/types/comments";
import {useAuth} from "@app/auth/auth-context";
import {UserRolesEnum} from "@app/types";
import {Tools} from "@app/utils/tools";

interface CommentModalProps extends ModalProps {
  requestId: string;
  onSuccess?: () => void;
}

export const CommentModal: FC<CommentModalProps> = ({requestId, onSuccess, ...otherProps}) => {
  const {userRoles} = useAuth();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const [commentValue, setCommentValue] = useState(null);
  const [showToEmployee, setShowToEmployee] = useState(true);

  const {mutate, isLoading} = useMutation({
    mutationFn: (data: CommentPayload) => addComment(requestId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries('comments')
      if (onSuccess) {
        onSuccess()
      } else {
        toast.success(i18n.t('messages.success.addComment'));
      }
      otherProps.onCancel(null)
      setCommentValue(null)
    },
    onError: async () => {
      setCommentValue(null)
    }
  });

  const addCommentHandler = () => {
    const payload = {
      title: '',
      body: commentValue,
      showToEmployee
    }
    mutate(payload)
  }

  useEffect(() => {
    setCommentValue(null)

    let timeoutId;
    if (otherProps.open && inputRef.current) {
      timeoutId = setTimeout(() => inputRef.current.focus(), 0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [otherProps.open, inputRef]);


  return (
    <Modal
      {...otherProps}
      width={450}
      centered
      footer={false}
      title={
        <Typography.Title level={3}>{i18n.t('comments.addComment')}</Typography.Title>
      }
    >
      <Form layout={'vertical'} className={'p-2'}>
        <Form.Item label={i18n.t('comments.inputLabel')}>
          <TextArea
            style={{maxHeight: '250px'}}
            ref={inputRef}
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
          />
        </Form.Item>
        <Space
          size={'large'}
          className={'w-100 mt-5 justify-content-center'}
          direction={'horizontal'}
        >
          {!userRoles.includes(UserRolesEnum.User) && (
            <Checkbox
              defaultChecked
              checked={showToEmployee}
              onChange={(e) => setShowToEmployee(e.target.checked)}>
              {i18n.t('comments.showToEmployeeLabel')}
            </Checkbox>
          )}
          <Button
            loading={isLoading}
            type={'primary'}
            disabled={Tools.isBlank(commentValue)}
            onClick={addCommentHandler}
          >
            {i18n.t('comments.addComment')}
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};
